:root {
    --Event-Orange-1: #E84E0E;
    --Event-Orange-2: #FF6626;
    --White: #FEFEFE;
    --Black: #25291C;
    --Event-Blue-1: #013466;
    --Event-Blue-2: #1492D2;
    --Event-Blue-3: #00E8FF;
    --Event-Green: #10B969;
    --Dark-Green: #00823E;
    --Light-Grey-2: rgba(241, 241, 241, 0.50);
    --Light-Grey-3: rgba(37, 41, 28, 0.20);
    --Gray-3: #828282;

    //transparency
    --Transparent-White-10: rgba(255, 255, 255, 0.1);
    --Transparent-Black-20: rgba(0, 0, 0, 0.20);
    --Transparent-Black-10: rgba(0, 0, 0, 0.1);
    --Border-Custom: rgba(37, 41, 28, 0.20);


    //gradients
    --Platinium-Gradient: linear-gradient(89deg, #A4A4A4 5.28%, #AB9A99 95.22%);
    --Gold-gradient:  linear-gradient(89deg, #CCA166 5.28%, #482913 95.22%);
    --Silver-gradient: linear-gradient(91deg, #ABBBCA 29.19%, #323E46 95.24%);
    --Bronze-gradient: linear-gradient(89deg, #936E64 5.28%, #361D1A 95.22%);
    --Meeting-gradient: linear-gradient(90deg, #013466 0%, #10B969 182.65%);
    --Supporting-gradient: linear-gradient(90deg, #1492D2 0%, #06618E 100%);
    --Media-gradient: linear-gradient(90deg, #1C5A97 0%, #072737 100%);
   //utilities 
    .green {
        color: var(--Event-Green);
    }
}