header {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 290px;
    color: var(--White);

    .container-fluid {
        min-height: 290px;
    }

    .title {
        font-size: 56px;
        line-height: 120%;
        font-weight: 800;
        margin-bottom: 32px;
    }

    .infos {
        max-width: 390px;
        background-color: var(--Event-Green);
        padding: 16px 24px;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 32px;

        .date {
            margin-bottom: 10px;
            color: var(--White);

            .bi {
                margin-right: 10px;
            }
        }

        .place {
            color: var(--White);

            .bi {
                margin-right: 10px;
            }
        }
    }
}

.header-text {
    margin-bottom: 56px;

    .bg-content {
        background: linear-gradient(90deg, var(--Event-Blue-1) 0%, var(--Event-Green) 182.65%);
        padding: 32px 0;
        color: var(--White);
        font-size: 20px;
    }
}

// global newsletter block style
.newsletter {
    background-image: url("../../img/illu2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 467px;
    padding-top: 80px;
    padding-bottom: 80px;
    color: var(--White);

    .green {
        color: var(--Event-Green);
    }

    .txt {
        font-size: 18px;
        margin-bottom: 40px;
    }

    .legal {
        font-size: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

//gallery 
.gallery {
    background-color: var(--Light-Grey-2);
    padding: 12px;

    @media screen and (min-width: 1130px) {
        padding: 56px;
    }

    .image {
        width: 100%;
        height: 227px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }
}

.menu-gallery-content {
    width: 200px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        top: 180px;

        li {
            border-top: 1px solid var(--Black);
            padding: 20px 16px;
            position: relative;
            font-size: 20px;
        }
    }
}

#gallery {
    .btn-close {
        cursor: pointer;
    }

    .photo {
        width: 100%;
        height: auto;
    }
}

.keynotes {
    background-color: var(--Light-Grey-2);
    padding: 12px;

    @media screen and (min-width: 1130px) {
        padding: 56px;
    }

    .files {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 16px 32px;
            position: relative;
            font-size: 20px;
            font-weight: 400;
            line-height: 140%;

            &:nth-child(odd) {
                background: #fff;
            }

            &:hover {
                font-weight: 700;
            }
        }
    }
}

.post-password-form {
    text-align: center;
}

.gallery-menu-mobile {
    position: sticky;
    top: 112px;
    background-color: #fff;
    padding: 16px 0;
    z-index: 2;

    .dropdown {
        .dropdown-menu {
            li {
                position: relative;
                padding: 10px;
                font-size: 20px;
            }
        }
    }
}