.page-sponsors {
    .sponsor-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 2.88px;
        text-transform: uppercase;
        color: #fff;
        padding: 16px 32px;

        &.platinium {
            background: var(--Platinium-Gradient);
        }

        &.gold {
            background: var(--Gold-gradient);
        }

        &.silver {
            background: var(--Silver-gradient);
        }

        &.bronze {
            background: var(--Bronze-gradient);
        }

        &.meeting {
            background: var(--Meeting-gradient);
        }

        &.supporting {
            background: var(--Supporting-gradient);
        }

        &.media {
            background: var(--Media-gradient);
        }
    }

    .items {
        .item {
            background-color: var(--Light-Grey-2);
            padding: 32px 32px 0 32px;
            @media screen and (max-width: 576px){
                padding: 16px 16px 0 16px;
            }
            &:last-child {
                .item-content {
                    border-bottom: none;

                }
            }

            .item-content {
                border-bottom: 1px solid var(--Border-Custom);
                padding-bottom: 32px;

                .logo {
                    max-width: 158px;
                }

                .description {
                    font-size: 16px !important;
                    line-height: 150%;
                }

                .name {
                    font-size: 20px;
                    font-weight: 800;
                    line-height: 150%;
                    color: var(--Event-Blue-2);
                }

                .website {
                    font-size: 16px;
                    line-height: 150%;
                    font-weight: 700;
                    color: var(--Event-Blue-2);
                    color: var(--Black);
                    margin-bottom: 16px;
                }
            }
        }
    }

    .anchors {
        position: sticky;
        top: 169px;
        border-top: 1px solid var(--Transparent-Black-20);

        a {
            display: block;
            padding: 20px 0;
            border-bottom: 1px solid var(--Transparent-Black-20);
            font-size: 24px;
            line-height: 150%;
            font-weight: 400;
            text-transform: uppercase;

            &.platinium {
                background: linear-gradient(89deg, #8C8C8C 5.28%, #777 5.28%, #AB9A99 95.22%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.gold {
                background: linear-gradient(89deg, #CCA166 5.28%, #482913 95.22%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.silver {
                background: linear-gradient(91deg, #ABBBCA 29.19%, #323E46 95.24%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.bronze {
                background: linear-gradient(89deg, #936E64 5.28%, #361D1A 95.22%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.meeting {
                background: linear-gradient(90deg, #013466 0%, #10B969 182.65%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.supporting {
                background: linear-gradient(90deg, #1492D2 0%, #06618E 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.media {
                background: linear-gradient(90deg, #1C5A97 0%, #072737 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .bold {
                font-weight: 800;
            }
        }
    }
}