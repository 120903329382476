body {
    &.home {
        .jumbo-header {
            background-color: var(--White);
            background-image: url("../../img/home-jumbo-2025.jpg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position-y: center;
            background-position-x: right;
            height: 800px;
            @media screen and (max-width: 992px) {
                background-image: none;
                
            }
            @media screen and (max-width: 1200px) {
                
                background-position-x: calc(100% + 20vw);
                
            }
            .title {
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 800;
                color: var(--Event-Orange-2);
                line-height: 150%;
                margin-bottom: 16px;
            }

            .sub-title {
                color: var(--Event-Green);
                font-size: 56px;
                font-weight: 800;
                line-height: 100%;
                margin-bottom: 32px;
            }

            .txt {
                color: var(--Event-Blue-1);
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 56px;
            }

            .date {
                font-size: 24px;
                font-weight: 700;
                color: var(--Event-Blue-1);
                line-height: 150%;
                margin-bottom: 10px;
            }

            .location {
                font-size: 24px;
                font-weight: 700;
                color: var(--Event-Blue-1);
                line-height: 150%;
                margin-bottom: 56px;
            }
        }

        .block-1 {
            background-image: url("../../img/illu1.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            min-height: 595px;
            padding-top: 112px;
            padding-bottom: 112px;
            color: var(--White);

            .illu {
                width: 100%;
            }

            .title {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
                margin-bottom: 16px;
            }

            .sub-title {
                /* font-size: 40px;
                font-weight: 800;
                line-height: 120%;
                margin-bottom: 24px; */

                .green {
                    color: var(--Event-Green);
                }
            }

            .txt {
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
            }
        }

        .programme {
            padding-top: 80px;
            padding-bottom: 80px;

            .list {
                border-top: 1px solid var(--Light-Grey-3);
                padding: 32px 0;

                .day {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 150%;
                    color: var(--Dark-Green);
                    margin-bottom: 8px;
                }

                .time {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 140%;
                    margin-bottom: 32px;
                    text-wrap: nowrap;
                }

                .speaker_list {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 140%;

                    .item {
                        font-size: 16px;
                        margin-bottom: 8px;

                        .rounded_photo {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            margin-right: 8px;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }

                .title {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 140%;
                }

                .content {
                    font-size: 14px;
                    line-height: 150%;

                }
            }
        }


        .speakers {
            padding-top: 80px;
            padding-bottom: 80px;
            background-color: var(--Light-Grey-2);
        }

        .sponsors {
            padding-top: 80px;
            padding-bottom: 80px;

            .title {
                width: 236px;
                margin: 0 auto;
                text-align: center;
            }
        }

        .attending-org {
            padding-top: 80px;
            padding-bottom: 80px;
            background-color: var(--Light-Grey-2);
            font-size: 18px;
            text-align: justify;

            .item {
                &:last-child {
                    .bi {
                        display: none;
                    }
                }
            }
        }

        .about-recs {
            padding-top: 80px;
            padding-bottom: 80px;

            .illu {
                width: 100%;
            }

            .title {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 700;
                color: var(--Event-Orange-1);
                line-height: 150%;
                margin-bottom: 16px;
            }

            .green {
                color: var(--Event-Green);
            }

            .txt {
                color: var(--Black);
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 24px;
            }
        }

    }
}