.page-programme {
    button {
        &.day {
            font-weight: 800;
            font-size: 24px;
            line-height: 140%;
            
            .green {
                margin-right: 16px;
            }
        }

        &.conf {
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            background-color: var(--White);
            .btn-conf-content {
                gap: 42px;

                .time {
                    color: var(--Event-Blue-1);
                    font-weight: 800;
                    width: 130px;
                    text-wrap: nowrap;
                }
            }

        }
    }
    .conf-content {
        gap: 42px;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        padding: 24px 40px;
        .time {
            color: var(--Event-Blue-1);
            font-weight: 800;
            width: 130px;
            text-wrap: nowrap;
        }
    }
    .conf-info {
        padding-left: 204px;
        background-color: var(--White);
        padding-right: 96px;
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
            
        }
        .speaker-info {
            margin-bottom: 32px;

            .photo-content {
                height: 64px;
                width: 64px;
                min-width: 64px;
                overflow: hidden;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name {
                color: var(--Event-Blue-2);
                font-size: 20px;
                font-weight: 700;
                line-height: 150%;
            }
        }
    }

}
.logo-sponsor-accorion {
    height: 30px;
    margin-left: auto;
}