#top-header {
    height: 113px;
    width: 100%;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.03), 0px 12px 36px -4px rgba(0, 0, 0, 0.05);
    font-weight: 700;
    position: fixed;
    top: 0;
    background-color: var(--White);
    z-index: 1000;
.offcanvas {
    .offcanvas-header {
        padding-bottom: 0;
        .close-btn {
            background-color: #fff;
            border: none;
            font-size: 28px;
        }
    }
}
    #header-logo {
        height: 65px;
        width: 161px;
    }

    .menu-header-menu-container {
        .menu {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            gap: 8px;
            user-select: none;
            @media screen and (min-width: 992px){
                display: inline-flex;
                flex-direction: row;
            }

            .menu-item {
                
                align-items: center;
                padding: 4px 12px;
                cursor: pointer;
                position: relative;
                @media screen and (min-width: 992px){
                    display: inline-flex;
                    
                }
                &.external-link {
                    &:after {
                        content: "\f1c5";
                        font-family: "bootstrap-icons";
                        font-size: 18px;
                        line-height: 24px;
                        margin-left: 8px;
                        font-weight: 300;
                        transition: color 0.15s ease-in-out;
                        margin-top: -4px;
                    }
                }

                &.btn {
                    padding: 8px 24px;

                    a {
                        color: inherit;
                    }
                }

                &:hover {
                    &.external-link {
                        &:after {
                            color: var(--Event-Orange-2);
                        }
                    }
                    &.btn {
                        a {
                            color: inherit;
                        }
                    }

                    a {
                        color: var(--Event-Orange-2);
                    }
                }

                &.current_page_item {
                    &.btn {
                        a {
                            color: var(--White);
                        }
                    }
                    a {
                        color: var(--Event-Orange-2);
                        
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 16px;
                    color: var(--Black);
                    font-weight: 700;
                    transition: color 0.15s ease-in-out;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}