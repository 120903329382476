.page-venue {
    .place {
        color: var(--Event-Green);
    }
    .adr {
        border-left: 2px solid var(--Event-Green);
        padding-left: 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 32px;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .txt {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 32px;
    }
    .gmap_iframe {
        min-height: 358px;
    }
}