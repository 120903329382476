#general-footer {
    background-color: var(--Event-Blue-1);
    color: var(--White);
    padding-top: 80px;
    padding-bottom: 80px;
    font-weight: 700;
    user-select: none;
    //margin-top: 56px;

    .menu-footer-menu-container {
        margin-bottom: 56px;
        font-size: 20px;

        .menu {
            list-style: none;
            padding: 0;
            margin: 0;

            .menu-item {
                margin: 8px 0;
                padding: 0;
                display: block;
                text-align: center;
                a {
                    color: var(--White);
                    text-decoration: none;

                    &:hover {
                        color: var(--Event-Orange-2);
                    }
                }
            }

            @media screen and (min-width: 576px) {
                display: flex;
                gap: 32px;
                justify-content: center;
                .menu-item {
                    display: inline-block;
                    margin: 0;
                }
            }


        }
    }

    .past-recmm {
        max-width: 500px;
        margin: 0 auto;
        padding: 8px 16px;
        background-color: var(--Transparent-White-10);
        font-size: 20px;

        .items {
            display: flex;
            gap: 16px;

            .item {
                background-color: var(--Transparent-White-10);
                padding: 4px 16px;
                position: relative;
                font-size: 24px;

                a {
                    color: var(--White);
                    text-decoration: none;

                    &:hover {
                        color: var(--Event-Orange-2);
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .social-media {
        padding: 32px;
        width: 250px;
        font-size: 20px;
        line-height: 140%;

        a {

            color: var(--White);
            font-size: 28px;

            &:hover {

                color: var(--Event-Orange-2);

            }
        }

    }

    .legal-menu {
        display: flex;
        gap: 16px;
        justify-content: center;

        a {
            color: var(--White);
            text-decoration: none;

            &:hover {
                color: var(--Event-Orange-2);
            }
        }
    }

    .inextremis {
        a {
            color: var(--White);
            text-decoration: none;

            &:hover {
                color: var(--Event-Orange-2);
            }
        }
    }

    hr {
        margin-top: 80px;
        margin-bottom: 32px;
    }
}