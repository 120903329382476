* {
    box-sizing: border-box;
	outline: none !important; 
	&:focus-visible {
		outline: none !important;
	}
}
body {
    font-family: "inter", verdana, sans-serif;
    font-weight: 400;
	background-color: var(--White);
	color: var(--Black);
	font-size: 18px;
	min-height: 100vh;
	#global-content {
		min-height: calc(100vh - 443px);
		padding-top: 113px;
	}
	a {
		text-decoration: none;
		color: var(--Event-Blue-2);
		transition: color 0.15s ease-in-out;
		&:hover {
			text-decoration: none;
		}
	}
	hr {
		border: 0;
		height: 1px;
		background-color: var(--White);
		margin: 16px 0;
		opacity: 1;
	}
	b, strong {
		font-weight: 800;
	}
	
}