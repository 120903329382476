@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

/* @font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-HeavyItalic.eot');
    src: local('SpeakPro-HeavyItalic'),
        url('../fonts/SpeakPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-HeavyItalic.woff2') format('woff2'),
        url('../fonts/SpeakPro-HeavyItalic.woff') format('woff'),
        url('../fonts/SpeakPro-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-Bold.eot');
    src: local('SpeakPro-Bold'),
        url('../fonts/SpeakPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-Bold.woff2') format('woff2'),
        url('../fonts/SpeakPro-Bold.woff') format('woff'),
        url('../fonts/SpeakPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-Italic.eot');
    src: local('SpeakPro-Italic'),
        url('../fonts/SpeakPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-Italic.woff2') format('woff2'),
        url('../fonts/SpeakPro-Italic.woff') format('woff'),
        url('../fonts/SpeakPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro.eot');
    src: local('SpeakPro'),
        url('../fonts/SpeakPro.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro.woff2') format('woff2'),
        url('../fonts/SpeakPro.woff') format('woff'),
        url('../fonts/SpeakPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-Heavy.eot');
    src: local('SpeakPro-Heavy'),
        url('../fonts/SpeakPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-Heavy.woff2') format('woff2'),
        url('../fonts/SpeakPro-Heavy.woff') format('woff'),
        url('../fonts/SpeakPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-Light.eot');
    src: local('SpeakPro-Light'),
        url('../fonts/SpeakPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-Light.woff2') format('woff2'),
        url('../fonts/SpeakPro-Light.woff') format('woff'),
        url('../fonts/SpeakPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-BoldItalic.eot');
    src: local('SpeakPro-BoldItalic'),
        url('../fonts/SpeakPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/SpeakPro-BoldItalic.woff') format('woff'),
        url('../fonts/SpeakPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SpeakPro';
    src: url('../fonts/SpeakPro-LightItalic.eot');
    src: local('SpeakPro-LightItalic'),
        url('../fonts/SpeakPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SpeakPro-LightItalic.woff2') format('woff2'),
        url('../fonts/SpeakPro-LightItalic.woff') format('woff'),
        url('../fonts/SpeakPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
} */



.fw-800 {
    font-weight: 800;
}

.inter {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}