.page-speakers {
    #speaker {
        .modal-header {
            //align-items: start;

            .desc {
                gap: 16px;

                .photo-content {
                    height: 90px;
                    width: 90px;
                    min-width: 90px;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .infos {
                    .name {
                        font-size: 24px;
                        font-weight: 800;
                        line-height: 140%;
                        color: var(--Event-Blue-1);
                    }

                    .position {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 150%;
                    }

                    .company {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 150%;
                    }
                }
            }

            .btn-close {
                font-size: 20px;
                font-weight: 700;
                line-height: 150%;
                color: var(--Black);
                cursor: pointer;
                user-select: none;
                text-align: right;
            }
        }
    }

    .card-speaker {
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(1, 52, 102, 0.00) 0.2%, rgba(1, 52, 102, 0.90) 100%);
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        &:hover {
            &:after {
                opacity: 1;
            }

            .card-body {
                .card-title {
                    color: var(--Event-Blue-3);
                }
            }
        }
    }
}

// this style is used in multiple pages
.card-speaker {
    padding: 16px;
    position: relative;
    height: 100%;
    min-height: 300px;
    //max-width: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
    .tags {
        gap: 8px;

        .tag {
            font-size: 14px;
            line-height: 150%;
            font-weight: 800;
            text-transform: uppercase;
            border-radius: 2px;
            padding: 4px 8px;
            color: var(--White);

            &.Speaker {
                background-color: var(--Event-Blue-2);
            }

            &.Moderator {
                background-color: var(--Event-Orange-1);
            }
        }
    }

    .card-body {
        position: absolute;
        bottom: 16px;
        color: var(--White);
        z-index: 1;

        .card-title {
            color: var(--White);
            transition: color 0.2s ease-in-out;
            font-size: 18px;
        }
        .position {
            font-size: 14px;
        }
        .company {
            font-size: 14px;
            font-weight: 800;
        }
    }
}