.page-contact {
    .card {
        background-color: var(--Light-Grey-2);
        padding: 24px;
        border-radius: 8px;
        height: 100%;
        .bi{
            color: var(--Event-Orange-2);
            font-size: 24px;
            display: inline-block;
        }
        .title{
            font-size: 20px;
            font-weight: 800;
            line-height: 140%;
        }
        .txt{
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
