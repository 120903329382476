.page-registration {
    .tabs {
        margin-bottom: 56px;
        user-select: none;
        .tab {
            color: var(--Black);
            border: 1px solid var(--Light-Grey-3);
            border-right: none;
            padding: 16px 32px;
            position: relative;
            min-width: 268px;
            cursor: pointer;

            &:last-child {
                border-right: 1px solid var(--Light-Grey-3);
            }

            .title {
                font-size: 24px;
                font-weight: 800;
                line-height: 150%;
            }

            .txt {
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
                color: var(--Gray-3);
            }

            &.active {
                background-color: var(--Event-Green);
                border-top: 1px solid var(--Event-Green);
                border-bottom: 1px solid var(--Event-Green);
                margin: -4px 0;
                color: var(--White);

                .txt {
                    color: var(--White);
                }
            }

            &.disable {
                background-color: var(--Light-Grey-3);
                color: var(--Black);
            }
        }
    }

    .ticket-card {
        border: 1px solid var(--Light-Grey-3);
        border-radius: 2px;
        padding: 40px 32px;
        height: 100%;
        padding-bottom: calc(42px + 32px);
        position: relative;
        user-select: none;

        .title {
            //margin-bottom: 16px;
            color: var(--Event-Blue-1);
        }

        .sub-title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 32px;
        }

        .price {
            font-size: 64px;
            font-weight: 800;
            line-height: 120%;
            margin-bottom: 40px;

            .vat {
                font-size: 20px;
                font-weight: 400;
                line-height: 150%;
            }
        }

        .txt {
            font-size: 18px;
            line-height: 150%;
            p {
                &:last-child {
                    margin-bottom: 40px;
                }
            }
        }

        .btn {
            text-transform: uppercase;
            position: absolute;
            bottom: 32px;
            width: calc(100% - 64px);
        }
    }
}