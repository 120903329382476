.swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {}

    .swiper-button-next {
        font-size: 32px;
        color: var(--Event-Orange-2);
    }

    .swiper-button-prev {
        font-size: 32px;
        color: var(--Event-Orange-2);
    }

    &.homeSponsor {
        height: 83px;
        width: 236px;
        .swiper-notification {
            display: none;
        }
        .swiper-wrapper {
            height: 83px;
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                //background-color: var(--Transparent-Black-10);

                .logo {
                    display: block;
                    max-width: 236px;
                    max-height: 83px;
                    width: auto;
                    height: auto;
                }
            }
        }

    }
    &.homeattending {
        .swiper-slide {
            background-color: var(--Transparent-Black-10);
            text-align: center;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 800;
            padding: 4px 16px;
        }
    }
}